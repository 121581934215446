import { createContext, useState } from "react"

const HomeContext = createContext([{}, () => {}])

const HomeProvider = props => {

  const [auth, setAuth] = useState({
    email: 'mario.morales@nestconsultores.cl',
    password: 'Mario.Morales.2023'
  })

  return (
    <HomeContext.Provider value={[auth, setAuth]}>
      {props.children}
    </HomeContext.Provider>
  )
}

export {HomeContext, HomeProvider} 